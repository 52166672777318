<template>
  <view-item title="预约">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'P'" :method="operation.audit.click">确认预约</button-ajax>
      <button-ajax v-if="form.data.id" :method="operation.save.click">保存</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">预约信息</en-dropdown-item>
          <en-dropdown-item command="customer">客户档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">车辆档案</en-dropdown-item>
          <en-dropdown-item v-if="form.data.status?.code === 'P'" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        name="预约单"
      
        :ajax="{ action: 'GET /enocloud/service/reservation/query' }"
        :props="{ start: 'reservationStartDate', end: 'reservationEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
      <en-table-column label="预约单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>
        <template #form="{ data }">
          <en-form-item label="车主">
            <en-input v-model="data.customerName"></en-input>
          </en-form-item>
          <en-form-item label="车牌">
            <en-input v-model="data.plateNo"></en-input>
          </en-form-item>
          <en-form-item label="预约单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="单据日期">
            <en-date-picker v-model:start="data.preparedStartDate" v-model:end="data.preparedEndDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="来源">
            <select-maintain
              v-model="data.source"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => (params.paths = ['SVRVSRC'])
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="预约日期">
            <en-date-picker v-model:start="data.reservationStartDate" v-model:end="data.reservationEndDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.vehicle?.plateNo }}</span>
                <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
                <span>单号：{{ form.data.serialNo }}</span>
                <en-tag>{{ form.data.status?.message }}</en-tag>
                <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
              </div>
            </template>
            <div class="grid grid-cols-4 gap-x-6">
              <span>服务顾问：{{ form.data.advisor?.name }}</span>
              <span>送修人：{{ form.data.senderName }}</span>
              <span>电话：{{ form.data.senderCellphone }}</span>
              <span>本次历程： {{ form.data.currentMileage }}</span>
              <span>是否保养： {{ form.data.maintenanceFlag?.message }}</span>
              <span>下保里程： {{ form.data.nextMaintenanceMileage }}</span>
              <span>下保时间： {{ formatDate(form.data.nextMaintenanceDate) }}</span>
              <span>单据日期： {{ formatDate(form.data.preparedDatetime) }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="项目"
                name="maintenance"
                :badge="form.data.maintenances?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/common/maintenance',
                      params: (params, value) => (params.payload = { quickSearch: value, ignoreSpraySurface: true })
                    }"
                    :props="{ label: 'name', value: '', disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) => Boolean(form.data.maintenances?.find(item => item.maintenance?.id === option.id))}"
                    value-key="id"
                    placeholder="请选择维修项目"
                    remote
                    class="w-110"
                    @change="form.maintenances.operation.add.change"
                  ></select-maintain>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic
                      code="SRDMF"
                      :data="form.data.maintenances"
                      :height="height"
                      show-selectable
                      @selection-change="form.maintenances.selection.change"
                    >
                      <template #OPERATION="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceReservationMaintenanceDto'], $index: number }">
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <reservation-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></reservation-detail>

  <reservation-dialog v-model="dialog.visible"></reservation-dialog>
</template>

<script lang="ts">
import { EnMessageBox } from '@enocloud/components'

import ReservationDetail from '@service/components/reservation-detail.vue'
import ReservationDialog from '@service/components/reservation-dialog.vue'

export default factory({
  components: { ReservationDetail, ReservationDialog },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        audit: {
          async click() {
            const res = await EnMessageBox.prompt('备注', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' })
            await this.form.audit({ paths: [this.form.data.id], addition: { id: this.form.data.id, comment: res.value } })
            return this.form.get()
          }
        },
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        delete: {
          async click() {
            await this.form.delete({ paths: [this.form.data.id] })
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
                await this.form.delete({ paths: [this.form.data.id] })
                this.form.get()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudServiceDefinitions['ServiceReservationQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'maintenance'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/reservation/:serviceReservationId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service/reservation',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/service/reservation/:serviceReservationId'
          },
          audit: {
            action: 'POST /enocloud/service/reservation/:serviceReservationId/audit'
          },
          discard: {
            action: 'POST /enocloud/service/reservation/:serviceReservationId/discard'
          }
        },
        children: {
          maintenances: {
            selection: {
              data: [] as EnocloudServiceDefinitions['ServiceReservationMaintenanceDto'][],
              change(rows: EnocloudServiceDefinitions['ServiceReservationMaintenanceDto'][]) {
                this.form.maintenances.selection.data = rows
              }
            },
            operation: {
              add: {
                change(value: EnocloudServiceDefinitions['MaintenanceDto']) {
                  this.form.data.maintenances.push({ maintenance: value, laborHour: 1, price: 0, discountRate: 0 })
                }
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      }
    }
  }
})
</script>
